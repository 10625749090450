import 'bootstrap/dist/css/bootstrap.min.css';
import './src/scss/main.scss';

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  //script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

const addStyle = (url) => {
  const style = document.createElement('link');
  style.rel = 'stylesheet';
  style.type = 'text/css';
  style.href = url;
  style.defer = true;
  document.head.appendChild(style);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://seal-dallas.bbb.org/logo/texas-tax-protest-90533600.js');
    addStyle('https://seal-dallas.bbb.org/logo/2-texas-tax-protest-90533600.css');
  };
};
